import React from 'react';
import loadable from '@loadable/component';

import SEO from '../components/SEO';
import { contentFetch } from '../lib/content-fetcher';

import RegisterForm from '../components/RegisterForm';

import PrivateRoute from '../components/PrivateRoute';

import styled from 'styled-components';
import { BREAKPOINTS } from '../utils/theme';

const Container = styled.div`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0 24px;
  }
`;

const Header = loadable(() => import('../components/Header'));

const ProfileEdit = () => {
  const [user, setUser] = React.useState(null);
  const [fullUser, setFullUser] = React.useState(null);
  const [status, setStatus] = React.useState('idle');

  const setLoadedData = (data) => {
    if (!data) {
      setStatus('error');
      setFullUser(null);
      return;
    }
    setStatus('done');
    setFullUser(data);
    return;
  };

  React.useEffect(() => {
    contentFetch({
      pathname: '/users/me',
      setState: setUser,
    });
  }, []);
  React.useEffect(() => {
    if (!user?.id) return;
    setStatus('loading');
    contentFetch({
      pathname: `/users/${user.id}`,
      setState: setLoadedData,
    });
  }, [user]);
  return (
    <>
      <Container>
        {status === 'idle' ? <p>Your profile data will be loaded shortly</p> : null}
        {status === 'loading' ? <p>Loading profile data</p> : null}
        {status === 'error' ? <p>There was an error loading your profile data.</p> : null}
      </Container>
      {status === 'done' && fullUser ? <RegisterForm currentUser={fullUser}></RegisterForm> : null}
    </>
  );
};

const ProfilePage = ({ location }) => {
  const [menuIsShown, showMenu] = React.useState(false);

  return (
    <main>
      <SEO title="Profile"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />

      <div style={{ maxWidth: '75ch', margin: '10rem auto 0' }}>
        <Container>
          <h1>Profile Edit</h1>
        </Container>
        <PrivateRoute component={ProfileEdit} location={location}></PrivateRoute>
      </div>
    </main>
  );
};

export default ProfilePage;

import React from 'react';
import { navigate } from 'gatsby';
import { isSSR } from '../utils/constants';
import { isLoggedIn } from '../lib/local-storage';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/`) {
    if (!isSSR) navigate('/');
    return null;
  }
  return <Component {...rest} />;
};
export default PrivateRoute;
